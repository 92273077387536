import React from "react";
import { Link } from 'gatsby';
import ContactLayout from '../layout/content';
import { useArticlesByCategory } from '../hooks/getArticles';
import { Riches } from '../components/category';

export default () => {
    const articles = useArticlesByCategory( 'riches' );

    return <ContactLayout>
        <h1>Articles tagged <Riches/></h1>
        <ul>
            { articles.map( ( article, i ) => <li key = {i}><Link to={article.path}>{article.context.frontmatter.title}</Link></li>)}
        </ul>
    </ContactLayout>
}